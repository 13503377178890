import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.THIRD_PARTY.baseUrl;

export const ott = {
  path: 'ott',
  name: `${basePath}-ott`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics" */ '@/views/private/modules/thirdParty/analytics/Ott.vue'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Ott', { name: `${basePath}-ott` }, {}),
    requiresAuth: true,
    title: 'Ott',
    contextSecure: CONTEXTS.THIRD_PARTY.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.SELLER.id],
  },
  props: true,
};

export default ott;
