import { CONTEXTS } from '@/model/shared/contexts';
import { BreadcrumbItem } from '@/entities/BreadcrumbItem';
import { ROLES } from '@/model/shared/roles';

const basePath = CONTEXTS.DASHBOARD_SSP.baseUrl;

export const skin = {
  path: 'skin',
  name: `${basePath}-skin`,
  component: () =>
    import(/* webpackChunkName: "tp-analytics" */ '@/views/private/modules/dashboardSSP/analytics/skin/Skin'),
  meta: {
    breadcrumbs: new BreadcrumbItem('Skin', { name: `${basePath}-skin` }, {}),
    requiresAuth: true,
    title: 'Skin',
    contextSecure: CONTEXTS.DASHBOARD_SSP.id,
    rolesAccepted: [ROLES.ADMIN.id, ROLES.SELLER.id],
  },
  props: true,
};
